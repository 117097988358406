import React from 'react';
import {Inline} from '@nib/layout';
import {graphql} from 'gatsby';
import {ErrorPageOwnProps} from '../types/pages';
import {PrimaryButton, SecondaryButton} from '@nib-components/button';
import SEO from '../components/SEO';
import PageTheme from '../components/PageTheme';
import {isNibOrDentalBrand} from '../services/utils';
import ErrorTemplate from '@nib-components/error-template';

const Error: React.FC<ErrorPageOwnProps> = ({data}) => {
  const goBack = () => {
    history.back();
  };

  let thereIsPreviousPage;
  if (typeof document !== 'undefined') {
    thereIsPreviousPage = history.length > 1;
  }

  return (
    <PageTheme>
      <SEO {...data.pageDetails.metadata} />
      <ErrorTemplate title="[404]" description="It looks like the page you're looking for cannot be found.">
        {isNibOrDentalBrand() && (
          <Inline space={{xs: 2, md: 4}} align="center">
            {thereIsPreviousPage && (
              <PrimaryButton component="button" onClick={goBack} id="ga-inlink-404-go-back">
                Back
              </PrimaryButton>
            )}
            <SecondaryButton href="/" id="ga-inlink-404-go-to-homepage">
              Go to homepage
            </SecondaryButton>
          </Inline>
        )}
      </ErrorTemplate>
    </PageTheme>
  );
};

export default Error;

export const query = graphql`
  query ErrorPageQuery {
    pageDetails(template: {eq: "404"}) {
      metadata {
        title
      }
    }
  }
`;
